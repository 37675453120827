<template>
	<div>
		<el-dialog :title="examInfo.exam_title" :visible.sync="dialogShow" :close-on-click-modal="false"
			@close="closeDialog" width="800px" top="60px">
			<div>
				<div v-for="(tm,tmidx) in TMList" :key="'tm'+tm.id">
					<div>
						<span v-if="tm.qst_type=='radio'">【单选】</span>
						<span v-if="tm.qst_type=='checkbox'">【多选】</span>
						<span v-if="tm.qst_type=='panduan'">【判断】</span>
						<span v-if="tm.qst_type=='tiankong'">【填空】</span>
						<span v-if="tm.qst_type=='zhuguan'">【主观】</span>
						{{tmidx+1}}、{{tm.qst_title}}
					</div>
					<div style="padding: 20px;">
						<div v-for="(xx,xxidx) in tm.xx">
							<div>
								<span v-if="xx.iscorrect" class="xxok">【正确】</span>
								<span v-else class="xxfalse">【错误】</span>
								{{xx.xxtitle}}
							</div>
							<div>
								<div class="processbox">
									<div class="processinner"  :style="{width:((xx.selected/xx.total)*100)+'%'}">
										
									</div>
									<div class="processtext">
										{{xx.selected}}/{{xx.total}}
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: ["exam_id"],

		data() {
			return {
				dialogShow: true,
				examInfo: {
					exam_title: ""
				},
				TMList: []
			}
		},
		mounted() {
			this.getExam()
		},
		methods: {
			getExam() {
				this.$http.post("/api/t_exam_one_tongji", {
					exam_id: this.exam_id
				}).then(res => {
					this.examInfo = res.data
					this.TMList = res.data.tm
				})
			},
			closeDialog() {
				this.$parent.showResult = false
			}
		}
	}
</script>

<style>
	.xxfalse{ background-color: orange; color: #fff; display: inline-block; font-size: 10px; border-radius: 4px;}
	.xxok{ background-color: lightgreen; color: #fff; display: inline-block; font-size: 10px; border-radius: 4px;}
	.processbox{ height: 16px; background-color: #ccc; margin-bottom: 10px; margin-top: 5px; border-radius: 8px; position: relative;}
	.processinner{height: 16px; background-color: rgb(55,182,255); border-radius: 8px;}
	.processtext{ position: absolute; width: 100%; font-size: 12px; text-align: center; line-height: 16px; top: 0;}
</style>
