<template>
	<div>
		<el-dialog :title="examInfo.exam_title" :visible.sync="dialogShow" :close-on-click-modal="false"
			@close="closeDialog" width="800px" top="60px">
			<div v-if="examInfo.id">
				<div  class="wj" :style="{backgroundImage:'url('+examInfo.ivst_bgimg+')'}">
				
				
					
					<div v-if="student" style="font-size: 16px; font-weight: bold; text-align: center;  text-align: center;">
						<span style="margin-right: 20px;">姓名：{{student.stu_name}}</span>
						<span style="margin-right: 20px;">得分：{{answers[0].totalscore}}</span>
						<span style="margin-right: 20px;">用时：<span v-if="answers[0].second>60">{{parseInt(answers[0].second/60)+"分"+parseInt(answers[0].second%60)+"秒"}}</span>
						<span v-if="answers[0].second<=60">{{answers[0].second}}秒</span></span>
					</div>
				
					<div style=" padding: 10px 0; padding-bottom: 30px;">{{examInfo.exam_desc}}</div>
				
					<div class="tm" v-for="(tm,tmidx) in TMList" style="margin-bottom: 20px;">
						<div style="margin-bottom: 10px;">
							<div v-if="tm.qst_type=='tiankong'">
								{{tmidx+1}}、<div class="tiankong" v-html="tm.qst_title"></div>
								<div v-if="!tm.sp" class="btnsp xxok1" @click="tkDaan(tm,1)" title="点击批改">回答正确</div>
								<div v-if="!tm.sp" title="点击批改" class="btnsp xxfalse1" @click="tkDaan(tm,0)">回答错误</div>
							</div>
							<div v-else-if="tm.qst_type=='zhuguan'">
								{{tmidx+1}}、{{tm.qst_title}} <div v-if="!tm.sp" class="btnsp xxok1" @click="tkDaan(tm,1)" title="点击批改">回答正确</div> <div v-if="!tm.sp" title="点击批改" class="btnsp xxfalse1" @click="tkDaan(tm,0)">回答错误</div>
							</div>
							<div v-else>
								{{tmidx+1}}、{{tm.qst_title}}
							</div>
							
							<div v-for="(file,idx) in tm.qst_files" :key="idx" v-if="tm.qst_files&&tm.qst_files.length>0">
								<img :src="file.src" style="width: 80%;" />
							</div>
				
						</div>
						<div v-if="tm.qst_type=='radio'||tm.qst_type=='panduan'">
							<div v-for="(xx,xxidx) in tm.xx" :class="['xxitem',xx.choosed?'choosed':'']"
								>
								<i v-if="xx.choosed" class="iconfont">&#xe6a2;</i>
								<i v-else class="iconfont">&#xe619;</i>
								{{getOrderList(xxidx)}}、{{xx.xxtitle}}   	<span v-if="xx.iscorrect" class="xxok">【此项正确】</span>
								<span v-else class="xxfalse">【此项错误】</span>
								
								<div v-for="(file,idx) in xx.files" :key="idx" v-if="xx.files&&xx.files.length>0">
									<img :src="file.src" style="width: 80%;" />
								</div>
							</div>
						</div>
				
						<div v-if="tm.qst_type=='checkbox'">
							<div v-for="(xx,xxidx) in tm.xx" :class="['xxitem',xx.choosed?'choosed':'']"
								>
								<i v-if="xx.choosed" class="iconfont">&#xe61b;</i>
								<i v-else class="iconfont">&#xe622;</i>
								{{getOrderList(xxidx)}}、{{xx.xxtitle}}
								<span v-if="xx.iscorrect" class="xxok">【此项正确】</span>
								<span v-else class="xxfalse">【此项错误】</span>
								<div v-for="(file,idx) in xx.files" :key="idx" v-if="xx.files&&xx.files.length>0">
									<img :src="file.src" style="width: 80%;" />
								</div>
							</div>
						</div>
				
						<div v-if="tm.qst_type=='zhuguan'" style="width: 94%;margin: 0 auto;">
							<textarea readonly="readonly" :value="tm.answer" style="border: 1px solid #EEE; width: 90%; padding:4px 10px; outline: none;"
								placeholder="请输入答案解析"></textarea>
						</div>
				
				
				
					</div>
				
					
				
					<div style="height: 15px;"></div>
				
				
				
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: ["exam_id","stu_id"],

		data() {
			return {
				dialogShow: true,
				examInfo: {
					exam_title: ""
				},
				TMList: [],
				answers:[],
				student:null
			}
		},
		mounted() {
			this.getExam()
		},
		methods: {
			getExam() {
				this.$http.post("/api/exam_stu_answer_detail", {
					exam_id: this.exam_id,
					stu_id:this.stu_id
				}).then(res => {
					for(let ans of res.data.answers){
						ans.answers = JSON.parse(ans.answers)
					}
					for (let tm of res.data.exam.tm) {
						tm.qst_title=tm.qst_title+"(此题"+tm.score+"分)"
						if(tm.qst_files){
							tm.qst_files = JSON.parse(tm.qst_files)
						}
						if (tm.xx) {
							for (let xx of tm.xx) {
								xx.choosed = false
								if(xx.files){
									xx.files = JSON.parse(xx.files)
								}
							}
						}
						if (tm.qst_type == "tiankong") {
							tm.qst_title = tm.qst_title.replace(/___/g, "<input type='text' value='xxx' readonly />").replace(/_/g,'')
						}
					}
					
					for(let i=0;i<res.data.exam.tm.length;i++){
						res.data.exam.tm[i] = this.getCheckAnswer(res.data.exam.tm[i],res.data.answers)
						let tm = res.data.exam.tm[i]
						if (tm.qst_type == "tiankong") {
							for(let xx of tm.answer){
								tm.qst_title = tm.qst_title.replace("xxx",xx)
							}
							
						}
					}
					
					console.log(res.data.exam.tm)
					this.examInfo = res.data.exam
					this.TMList = res.data.exam.tm
					this.answers = res.data.answers
					this.student = res.data.stu
				})
			},
			getCheckAnswer(tm,answer){
				
				for(let ans of answer){
					
					if(ans.tmid == tm.id){
						if(tm.qst_type=="radio"||tm.qst_type=="checkbox"||tm.qst_type=="panduan"){
							for(let xx of tm.xx){
								if(ans.answers[xx.id]){
									 xx.choosed = true
								}
							}
							
							tm.sp = ans.sp
							
						}else if(tm.qst_type=="zhuguan"){
							tm.answer = ans.answers[0]
							tm.sp = ans.sp
						}else if(tm.qst_type=="tiankong"){
							tm.answer=[]
							for(let i=0;i<10;i++){
								if(ans.answers[i]){
									tm.answer.push(ans.answers[i])
								}else{
									break
								}
							}
							tm.sp = ans.sp
						}
					}
					
					
				}
				
				return tm
			},
			closeDialog() {
				this.$parent.showResult = false
			},
			tkDaan(tm,status){
				console.log(tm)
				this.$http.post("/api/timu_shenpi", {
					exam_id: this.exam_id,
					stu_id:this.stu_id,
					tm_id:tm.id,
					score:status?tm.score:0
				}).then(res => {
					this.$message.success("批改成功！")
					this.getExam()
				})
			}
		}
	}
</script>

<style scoped="scoped">
	
		.wj {
			padding: 0 15px;
			word-break: break-all;
			overflow: auto;
			background-size: cover;
			height: 100%;
		}
	
		.required {
			color: orangered;
		}
	
		.notrequired {
			color: #FFFFFF;
		}
	
		.xxitem {
			margin-left: 25px;
			padding: 10px;
			border: 1px solid #f6f6f6;
			margin-bottom: 5px;
			border-radius: 4px;
		}
	
		.choosed {
			color: #007AFF;
			border: 1px solid #007AFF;
	
		}
	
		.iconfont {
			display: inline-block;
			transform: translateY(2px);
			vertical-align: top;
		}
	
		.btntj {
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #007AFF;
			color: #FFFFFF;
			border-radius: 4px;
		}
	
		.toast {
			position: fixed;
			width: 70%;
			left: 15%;
			text-align: center;
			padding: 5px 0;
			border-radius: 4px;
			bottom: 50%;
			background: orangered;
			color: #FFFFFF;
			font-size: 14px;
			opacity: 0;
			display: none;
		}
	
		.alert {
			position: fixed;
			display: none;
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 99;
			background: rgba(0, 0, 0, 0.6);
			opacity: 0;
		}
	
		.alertbox {
			width: 80%;
			background: #FFFFFF;
			border-radius: 4px;
			padding: 10px;
			margin: 0 auto;
		}
	
		.alerttitle {
			font-size: 16px;
			text-align: center;
			border-bottom: 1px solid #f6f6f6;
			padding-bottom: 10px;
		}
	
		.alertcontent {
			padding: 20px 0;
			word-break: break-all;
			text-align: center;
		}
	
		.alertbutton {
			border-top: 1px solid #f6f6f6;
			padding: 10px;
			text-align: center;
			background-color: #007AFF;
			color: #fff;
			border-radius: 6px;
	
		}
	
		.tiankong {
			display: inline-block;
		}
	.tiankong>input[type='text'] {
			border: 0;
			outline: none;
			width: 100px;
			border-bottom: 1px solid #888;
			text-align: center;
		}
		.xxfalse{ background-color: orange; color: #fff; display: inline-block; font-size: 10px; border-radius: 4px;}
		.xxok{ background-color: lightgreen; color: #fff; display: inline-block; font-size: 10px; border-radius: 4px;}
		
		.btnsp{ display: inline-block; padding: 2px 10px; border-radius: 4px; font-size: 12px; color: #FFFFFF; cursor: pointer; margin-right: 10px;}
		
		.xxfalse1{ background-color: firebrick; color: #fff; display: inline-block; font-size: 10px; border-radius: 4px;}
		.xxok1{ background-color: deepskyblue; color: #fff; display: inline-block; font-size: 10px; border-radius: 4px;}
		
</style>
