<template>
	<div>
		<el-dialog :title="examInfo.exam_title" :visible.sync="dialogShow" :close-on-click-modal="false"
			@close="closeDialog" width="1000px" top="60px">
			<div>
				<div style="text-align: right;">
					<div style="float: left;">
						共：{{DataList.length}}人。
						按分数段：<el-input v-model="min_score" size="mini" placeholder="分数段" style="width: 100px;"></el-input>
						-
						<el-input v-model="max_score" size="mini" placeholder="分数段" style="width: 100px; "></el-input>
						
						<el-button icon="el-icon-search" type="primary" size="mini" style="margin-left: 20px;" @click="getExam">查询</el-button>
					</div>
					<el-button icon="el-icon-download" type="primary" size="small" @click="exportXls">导出</el-button>
				</div>
				<el-table stripe :data="DataList" size="mini" border stripe>

					<el-table-column prop="id" width="80" label="序号">
						<template slot-scope="scope">
							{{scope.$index+1}}

						</template>
					</el-table-column>
					<el-table-column prop="stu_name" label="学生姓名" width="140"></el-table-column>
					<el-table-column prop="stu_code" label="学号" min-width="100"></el-table-column>
					<el-table-column prop="class_name" label="班级" min-width="100"></el-table-column>
					<el-table-column prop="exam_date" label="考试日期" min-width="100"></el-table-column>
					<el-table-column prop="totalscore" label="得分数" sortable="" min-width="100"></el-table-column>
					<el-table-column prop="second" label="用时时长" min-width="100">
						<template slot-scope="scope">
							<span
								v-if="scope.row.second>60">{{parseInt(scope.row.second/60)+"分"+parseInt(scope.row.second%60)+"秒"}}</span>
							<span v-if="scope.row.second<=60">{{scope.row.second}}秒</span>
						</template>
					</el-table-column>

					<el-table-column fixed="right" label="操作" width="160">
						<template slot-scope="scope">

							<el-button @click="viewDetail(scope.row)" type="text" size="small">详情</el-button>

						</template>
					</el-table-column>


				</el-table>
			</div>
			<div style="display: none;">
				<table border="" cellspacing="" cellpadding="" id="datatable">
					<tr>
						<td>序号</td>
						<td>姓名</td>
						<td>学号</td>
						<td>班级</td>
						<td>考试日期</td>
						<td>得分</td>
						<td>用时长</td>
					</tr>
					<tr v-for="(item,idx) in DataList" :key="idx">
						<td>{{idx+1}}</td>
						<td>{{item.stu_name}}</td>
						<td>{{item.stu_code}}</td>
						<td>{{item.class_name}}</td>
						<td>{{item.exam_date}}</td>
						<td>{{item.totalscore}}</td>
						<td><span
								v-if="item.second>60">{{parseInt(item.second/60)+"分"+parseInt(item.second%60)+"秒"}}</span>
							<span v-if="item.second<=60">{{item.second}}秒</span>
						</td>
					</tr>

				</table>
			</div>
		</el-dialog>
		<oneDetail v-if="showResult" :exam_id="exam_id" :stu_id="stu_id"></oneDetail>
	</div>
</template>

<script>
	import oneDetail from "./oneDetail.vue"
	import XLSX from 'xlsx'
	import FileSaver from 'file-saver'
	export default {
		components: {
			oneDetail
		},
		props: ["exam_id", "examtitle"],

		data() {
			return {
				dialogShow: true,
				examInfo: {
					exam_title: ""
				},
				min_score:"",
				max_score:"",
				DataList: [],
				showResult: false,
				stu_id: ""
			}
		},
		mounted() {
			this.examInfo.exam_title = this.examtitle
			this.getExam()
		},
		methods: {
			getExam() {
				this.$http.post("/api/exam_stujoin_list", {
					exam_id: this.exam_id,
					min_score:this.min_score,
					max_score:this.max_score,
				}).then(res => {

					this.DataList = res.data
				})
			},
			closeDialog() {
				this.$parent.showUsers = false
			},
			viewDetail(e) {
				this.stu_id = e.id;
				this.showResult = true
			},
			exportXls() {


				let _this = this

				/* out-table关联导出的dom节点  */
				var wb = XLSX.utils.table_to_book(document.querySelector('#datatable'))
				/* get binary string as output */
				var wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				})


				let exportTitle = `测试`
				try {
					FileSaver.saveAs(new Blob([wbout], {
						type: 'application/octet-stream'
					}), `${this.examInfo.exam_title}.xlsx`)
				} catch (e) {
					if (typeof console !== 'undefined') console.log(e, wbout)
				}
				return wbout

			}
		}
	}
</script>

<style>
	.xxfalse {
		background-color: orange;
		color: #fff;
		display: inline-block;
		font-size: 10px;
		border-radius: 4px;
	}

	.xxok {
		background-color: lightgreen;
		color: #fff;
		display: inline-block;
		font-size: 10px;
		border-radius: 4px;
	}

	.processbox {
		height: 16px;
		background-color: #ccc;
		margin-bottom: 10px;
		margin-top: 5px;
		border-radius: 8px;
		position: relative;
	}

	.processinner {
		height: 16px;
		background-color: rgb(55, 182, 255);
		border-radius: 8px;
	}

	.processtext {
		position: absolute;
		width: 100%;
		font-size: 12px;
		text-align: center;
		line-height: 16px;
		top: 0;
	}
</style>
